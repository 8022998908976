import { computed, reactive } from 'vue';
import { setAvatar } from "@/store/visitorInfo"
const state = reactive({
    show: false,
    warn: '',
    code: '',
    mail: '',
    awaitCode: false,
    countdown: 0,
    awaitLogin: false
});

const sendCodeText = computed(() => {
    if (state.awaitCode && state.countdown) {
        return `已发送 ${state.countdown}s`
    }
    return '发送验证码'
})

function open() {
    state.show = true
}
function close() {
    state.show = false
    state.warn = ''
}
function changeMail(e) {
    state.mail = e.target.value
}
function changeCode(e) {
    state.code = e.target.value
}

async function sendCode() {
    if (state.awaitCode) return
    if (!state.mail) {
        state.warn = '请输入邮箱地址'
        return
    }
    if (!IsEmail(state.mail)) {
        state.warn = '请输入正确的邮箱地址'
        return
    }
    state.warn = ''
    state.awaitCode = true
    const { success, data, msg } = await $fetch.post('/visitor/sendCode', {
        mail: state.mail
    })
    if (success) {
        state.countdown = 60 - parseInt((new Date().getTime() - data) / 1000)
        startCountdown()
    } else {
        state.warn = msg
        state.awaitCode = false
    }
}

async function login() {
    if (state.awaitLogin) return
    if (!state.mail) {
        state.warn = '请输入邮箱地址'
        return
    }
    if (!IsEmail(state.mail)) {
        state.warn = '请输入正确的邮箱地址'
        return
    }
    if (!state.code) {
        state.warn = '请输入验证码'
        return
    }
    const target = document.getElementById("AgreementCheckBox")
    if (!target.checked) {
        state.warn = '请阅读并同意协议'
        return
    }
    state.warn = ''
    state.awaitLogin = true
    const { success, data, msg } = await $fetch.post('/visitor/login', {
        mail: state.mail,
        code: state.code
    })
    state.awaitLogin = false
    if (success) {
        const { avatar } = data
        setAvatar(avatar)
        close()
    } else {
        state.warn = msg
    }
}

function IsEmail(str) {
    var reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(str);
}
function startCountdown() {
    const timer = setInterval(() => {
        if (state.countdown <= 0) {
            state.countdown = 0
            state.awaitCode = false
            clearInterval(timer)
            return
        }
        state.countdown--
    }, 999)
}
export {
    state,
    open,
    close,
    changeMail,
    changeCode,
    sendCode,
    sendCodeText,
    login
};
