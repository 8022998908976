import { reactive } from 'vue';

export const statistical = reactive({
    location: { country: '', province: '', city: '', isp: '' },
    todayGrowth: { today: 0, yesterday: 0 },
    week: [],
    province: [],
});

export function setLocation(location) {
    statistical.location = location;
}

export function setTodayGrowth(todayGrowth) {
    statistical.todayGrowth = todayGrowth;
}
export function setWeek(week) {
    statistical.week = week;
}

export function setProvince(province) {
    statistical.province = province;
}
