<template>
    <div class='Footer'>
        <div class="back" :style="{ backgroundImage: `url(${bg})` }"></div>
        <div class="modal"></div>
        <div class="front">
            <div class="boxs">
                <div class="box qrcode">
                    <img :src="wechatQr" />
                </div>
                <div class="box links">
                    <div class="title">友情链接</div>
                    <router-link class="link" to="#">
                        友链空位,欢迎互联
                    </router-link>
                    <router-link class="link" to="#">
                        友链空位,欢迎互联
                    </router-link>
                    <router-link class="link" to="#">
                        友链空位,欢迎互联
                    </router-link>
                    <router-link class="link" to="#">
                        查看更多&申请 >>
                    </router-link>
                </div>
                <div class="box contact">
                    <div class="title">联系小王</div>
                    <div class="info">
                        <SvgQQ />
                        <span>2553378438</span>
                    </div>
                    <div class="info">
                        <SvgWechat />
                        <span>codemeow</span>
                    </div>
                    <router-link class="github" to="#">
                        <SvgGithub />
                    </router-link>
                </div>
                <div class="box">唯有时间对我们一视同仁</div>
            </div>
            <div class="copyright">
                粤ICP备20003149号 | Copyright © 2019 - 2022 - www.wdhhh.cn | By
                小王的程序人生
            </div>
        </div>
    </div>
</template>

<script setup>
import SvgQQ from "@/svg/qq.svg"
import SvgWechat from "@/svg/wechat.svg"
import SvgGithub from "@/svg/github.svg"
const bg = require("@/assets/image/footer-bg.jpg")
const wechatQr = require("@/assets/image/wechat_qr.webp")
</script>

<style scoped lang="scss">
.Footer {
    height: 331px;
    position: relative;
    z-index: 10;

    .back {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: repeat;
    }

    .modal {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .front {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        color: rgba(255, 255, 255, .5);
        font-size: 14px;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .copyright {
        margin-top: 20px;
    }

    .boxs {
        width: 1127px;
        height: 160px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding-bottom: 10px;
    }

    .box {
        width: 25%;
        height: 100%;
        border-left: 1px solid rgba(255, 255, 255, .1);
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
            border-left: 0;
        }
    }

    .qrcode img {
        width: 110px;
        height: 110px;
        border-radius: 3px;
    }

    .title {
        color: #FFF;
        margin-bottom: 5px;
    }

    .links {
        .link {
            color: rgba(255, 255, 255, .5);
            margin-bottom: 2px;
            transition: color .2s ease;

            &:hover {
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    .contact {
        .info {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 1px 0;

            svg {
                transition: fill .2s ease;
                fill: rgba(255, 255, 255, .7);
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }

            &:hover {
                color: rgba(255, 255, 255, 1);

                svg {
                    fill: rgba(255, 255, 255, 1);

                }
            }
        }

        .github {
            padding-top: 9px;
            fill: rgba(255, 255, 255, .7);

            svg {
                width: 28px;
                height: 28px;
            }

            &:hover {
                svg {
                    fill: rgba(255, 255, 255, 1);
                }
            }
        }
    }

}
</style>