import { setUrl } from './assets';

export async function initAssets() {
    // 静态图片资源 - 先用fetch请求,然后就可以走缓存了
    const bannerbBg = require('@/assets/image/banner-bg.webp');
    const bannerbBg2 = require('@/assets/image/banner-bg2.webp');
    const IS = require('@/assets/image/individuality-signature.webp');
    const HS = require('@/assets/image/handwritten-signature.webp');
    const avatar = require('@/assets/image/avatar.webp');
    const avatar2 = require('@/assets/image/avatar-2.webp');
    if (Math.random() > 0.5) {
        awaitSrc('banner-bg', bannerbBg2);
    } else {
        awaitSrc('banner-bg', bannerbBg);
    }
    awaitSrc('individuality-signature', IS);
    awaitSrc('handwritten-signature', HS);
    awaitSrc('avatar', avatar);
    awaitSrc('avatar-2', avatar2);

    return Promise.resolve();
}

async function awaitSrc(name, src) {
    setUrl(name, src);
    await fetch(src);
    return Promise.resolve();
}
