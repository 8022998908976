import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home';
import Blog from '../views/blog';
const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/blog/:aid',
        name: 'blog',
        component: Blog,
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/index.vue'),
    },
    {
        path: '/archive',
        name: 'archive',
        component: () => import('../views/archive/index.vue'),
    },
    {
        path: '/link',
        name: 'link',
        component: () => import('../views/link/index.vue'),
    },
    {
        path: '/tag',
        name: 'tag',
        component: () => import('../views/tag/index.vue'),
    },
    {
        path: '/traffic',
        name: 'traffic',
        component: () => import('../views/traffic/index.vue'),
    },
    {
        path: '/type',
        name: 'type',
        component: () => import('../views/type/index.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
