<template>
    <article class='Article'>
        <div v-show="!loading" class="wrap">
            <template v-if="article.id">
                <div class="close" @click="toHome">
                    <SvgBack />
                    关闭
                </div>
                <div class="title">
                    {{ article.title }}
                </div>
                <div class="meta">
                    <div>
                        <span>
                            发布于 {{ changeDateFormat(article.create_time).substring(0, 16).replace("-",
                                    '年').replace("-", '月').replace(" ", '日')
                            }}</span>
                        ·
                        <span> 更新于 {{ formatDistance(article.update_time) }}</span>
                    </div>
                    <div>
                        <span> 阅读 {{ article.view_count }}</span>
                        ·
                        <span> 评论 {{ commentLength }}</span>
                    </div>
                </div>
                <div class="cover" :style="{ backgroundImage: `url(${article.first_picture})` }" />
                <div class="content" :id="CONTENTID" @click="handlerContentClick" v-html="article.content" />
                <div class="footer">
                    <div class="foote_type">
                        <div class="label">
                            分类：
                        </div>
                        <div class="type">
                            <TypeItem :name="article.icon" /> <span>{{ article.type_name }}</span>
                        </div>
                    </div>


                    <div class="tag_type">
                        <div class="label">
                            标签：
                        </div>
                        <div class="tag">
                            <TagItem v-for="tag in article.tagList" :key="tag.id" :name="tag.name" :color="tag.color" />
                        </div>
                    </div>
                </div>
                <Comment :data="article.commentList" :commentLength="commentLength" @update="updateCommentList" />
            </template>
            <Empty v-else />
        </div>
        <transition name="fade">
            <Loading v-show="loading" />
        </transition>
    </article>
</template>

<script setup>import { useRouter, useRoute } from 'vue-router';
import { changeDateFormat, formatDistance } from "@/utils/changeDateFormat"
import TypeItem from '@cp/TypeItem'
import TagItem from '@cp/TagItem'
import SvgBack from "@/svg/back.svg"
import Loading from "./components/Loading"
import Comment from "./components/Comment"
import Empty from "./components/Empty"
import { open as openImgViewr } from "@cp/ImageViewr/state"
// import { data as article } from "./data.js"
import { ref, watch, computed, reactive } from 'vue';
import { setDirectory } from "@/store/directory"
const router = useRouter()
const route = useRoute()
const CONTENTID = 'ArticleContent'
const loading = ref(false)

const state = reactive({
    article: {
        id: NaN,
        commentabled: NaN,
        appreciation: NaN,
        content: "",
        create_time: "",
        description: "",
        first_picture: "",
        flag: "",
        published: NaN,
        title: "",
        update_time: "",
        view_count: NaN,
        type_id: NaN,
        type_name: "",
        icon: "",
        tagList: [],
        commentList: [],
    }
})
const articleId = computed(() => route.query && route.query.article)
const article = computed(() => state.article)
const commentLength = computed(() => {
    const { commentList } = state.article
    return commentList.reduce((pre, item) => {
        return pre + item.replys.length
    }, commentList.length)
})
watch(articleId, (id) => {
    if (!id) return
    loading.value = true
    $fetch.get("/publish/getArticleDetails", { id, }).then(res => {
        if (res.success) {
            state.article = res.data
        }
        setTimeout(() => {
            loading.value = false
            computeTitleDirectory()
        }, 300)
    })

}, { immediate: true })

function toHome() {
    router.push({ name: 'home' })
}
function handlerContentClick(e) {
    const { target } = e
    if (target.tagName !== 'IMG') return
    const imgArr = Array.from(document.querySelectorAll(`#${CONTENTID} img`))
    const index = imgArr.indexOf(target)
    const srcArr = imgArr.map(item => item.src)
    openImgViewr(srcArr, index)
}
// 递归计算目录
/**
 * [
 * {
 *  tag:'',
 *  chils:[]
 * }
 * ]
 */
function computeTitleDirectory() {
    const allowTags = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'H7']
    const contentEl = document.querySelectorAll(`#${CONTENTID}`)[0]
    const directory = []
    if (!contentEl) return

    recursive(contentEl, directory, true)

    function recursive(el, dic, root) {

        if (!el.tagName) return
        if (!allowTags.includes(el.tagName) && !root) return
        const node = {
            tag: el.tagName,
            id: el.id,
            chils: []
        }
        dic.push(node)

        const { childNodes } = el
        for (let i = 0; i < childNodes.length; i++) {
            recursive(childNodes[i], node.chils, false)
        }
    }

    setDirectory(directory[0].chils)
}

// 更新评论
function updateCommentList(value) {
    state.article.commentList = value
}
</script>

<style scoped lang="scss">
.Article {
    position: relative;
    // min-height: calc(100vh - 52px);
    width: 820px;
    background-color: #FFF;
    transition: all .3s ease;
    padding: 34px;
    padding-top: 40px;
    border-radius: 4px;
    margin-right: 20px;
    margin-left: 20px;
    overflow: hidden;

    .wrap {
        position: relative;
        width: 752px;
    }

    .close {
        position: absolute;
        top: -40px;
        left: -9px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #8590a6;
        font-size: 15px;
        padding: 6px 10px;
        border-radius: 4px;
        transition: all .2s ease;

        &:hover {
            background-color: #7952b3;
            color: #FFF;
        }

        svg {
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }
    }

    .title {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 1.31;
        color: #252933;
    }

    .meta {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #8a919f;
        line-height: 22px;
        margin-bottom: 20px;
    }


    .cover {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        margin-bottom: 16px;
    }

    .content {
        // min-height: 600px;
        width: 100%;
        overflow: hidden;
    }

    .footer {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .tag_type,
        .foote_type {
            display: flex;
            align-items: center;
        }

        .label {
            font-size: 14px;
            color: #515767;
            margin-right: 8px;
        }

        .type {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #8590a6;
            margin-right: 30px;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }
        }

        .tag {
            display: flex;
            align-items: center;
        }
    }

}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>
<style lang="scss">
@import "./hljs.scss";
@import "./markdown.scss";
</style>