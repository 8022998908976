<template>
    <div class='Comment'>
        <div class="input" :id="commentInputId">
            <div class="label">评论 {{ props.commentLength }}条</div>
            <div class="content">
                <div class="avatar" @click="avatarSelectOpen">
                    <Avatar :name="visitorInfo.avatar" />
                </div>
                <div class="input_text" :class="{ empty: !replyValue, focus: inputTipCls }" @input="inputText"
                    contenteditable="true" spellcheck="false" placeholder="请输入评论~">
                    {{ replyValue }}
                </div>
            </div>
            <div class="enter_bar">
                <div class="emoji">
                    <span>😀</span> emoji
                    <div class="emoji_list">
                        <div v-for="(e, index) in mans" @click="inputEmoji(e)" :key="index">{{ e }}</div>
                    </div>
                </div>
                <div class="warn_info" v-if="warnText">{{ warnText }}</div>
                <div class="reply_info" v-if="replyInfo.mail">
                    回复：<div class="mail">{{ replyInfo.mail }}</div>
                    <div class="close" @click="replyClick('', 0, 0)" />
                </div>
                <div class="post_btn" @click="postComment">发表评论</div>
            </div>
        </div>
        <div class="comment_list">
            <div class="comment_item" v-for="cData in props.data" :key="cData.id" :id="idFix + cData.id">
                <div class="avatar">
                    <Avatar :name="cData.avatar_svg" />
                </div>
                <div class="content">
                    <!-- 名字 -->
                    <div class="user_name">
                        {{ cData.mail }}
                    </div>
                    <!-- 文字 -->
                    <div class="comment_text">
                        {{ decode(cData.comment_content) }}
                    </div>
                    <!-- 信息 -->
                    <div class="info_bar">
                        <div class="reply_count" @click="replyClick(cData.mail, cData.id, cData.id)">
                            <SvgMessage />
                            {{ cData.replys.length || '回复' }}
                        </div>
                        <div class="reply_time">
                            {{ formatDistance(cData.created_time) }} · {{ contactAddr(cData) }}
                        </div>
                    </div>
                    <!-- 二级评论 -->
                    <div class="sub_comment" v-if="cData.replys.length">
                        <div v-for="subCData in cData.replys" :key="subCData.id" :id="idFix + subCData.id"
                            class="sub_comment_item">
                            <!-- 头像 -->
                            <div class="avatar">
                                <Avatar :name="subCData.avatar_svg" />
                            </div>
                            <div class="content">
                                <!-- 名字 -->
                                <div class="user_name">
                                    {{ subCData.mail }}
                                    <template v-if="subCData.master_id !== subCData.parent_id">
                                        <span>回复</span>
                                        {{ subCData.parentInfo.mail }}
                                    </template>
                                </div>
                                <!-- 文字 -->
                                <div class="comment_text">
                                    {{ decode(subCData.comment_content) }}
                                </div>
                                <!-- 被回复的内容 -->
                                <div class="parent_content" v-if="subCData.master_id !== subCData.parent_id">
                                    “ {{ decode(subCData.parentInfo.comment_content) }} ”
                                </div>
                                <!-- 信息 -->
                                <div class="info_bar">
                                    <div class="reply_count"
                                        @click="replyClick(subCData.mail, subCData.master_id, subCData.id)">
                                        <SvgMessage />回复
                                    </div>
                                    <div class="reply_time">{{ formatDistance(subCData.created_time) }} · {{
                                            contactAddr(subCData)
                                    }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, computed, watch } from 'vue';
import Avatar from "@cp/Avatar"
import { mans, encode, decode } from "./emoji"
import SvgMessage from "@/svg/message.svg"
import { formatDistance } from "@/utils/changeDateFormat"
import { scrollTo } from "@/utils/scroll"
import { open as loginOpen } from "@cp/LoginDialog/state"
import { state as visitorInfo, open as avatarSelectOpen } from "@/store/visitorInfo"
import { useRoute } from 'vue-router';
const route = useRoute()
const props = defineProps({
    data: Array,
    commentLength: Number
})
let lock = false
const idFix = 'CommentItem_'
const emits = defineEmits(['update']);
const warnText = ref('')
const inputTipCls = ref(false)
const replyValue = ref("")
const replyInfo = reactive({
    mail: '',
    master: 0,
    parent: 0
})
const articleId = computed(() => route.query && route.query.article)
watch(articleId, _ => {
    replyInfo.mail = ''
    replyInfo.master = 0
    replyInfo.parent = 0
})
const commentInputId = 'CommentInput'
function inputText(e) {
    replyValue.value = e.target.innerText;
}
function inputEmoji(value) {
    if (replyValue.value === '') {
        replyValue.value += value
        return
    }
    const baseSelect = window.getSelection().getRangeAt(0);
    if (baseSelect.startContainer?.wholeText === replyValue.value) {
        const inser = baseSelect.startOffset
        replyValue.value = replyValue.value.slice(0, inser) + value + replyValue.value.slice(inser)
        return
    }
    replyValue.value += value
}
function contactAddr({ country, province, city }) {
    return country + province + city
}
async function postComment() {
    const encodeStr = encode(replyValue.value)
    if (encodeStr) {
        if (lock) return
        lock = true
        const { success, data, msg } = await $fetch.post('/visitor/comment', {
            content: encodeStr.substr(0, 300),
            master: replyInfo.master,
            parent: replyInfo.parent,
            avatar: visitorInfo.avatar,
            article: parseInt(articleId.value)
        })
        if (!success) {
            if (data && !data.login) {
                loginOpen() // 需要登录
            } else {
                warnText.value = msg
            }
        } else {
            const { id, comment } = data
            emits('update', comment);
            replyClick('', 0, 0)
            replyValue.value = ''
            warnText.value = ''
            setTimeout(() => {
                const titleEl = document.getElementById(idFix + id)
                scrollTo(titleEl.offsetTop)
            }, 100)
        }
        lock = false
    } else {
        inputTipCls.value = true
        setTimeout(() => {
            inputTipCls.value = false
        }, 300)
        setTimeout(() => {
            inputTipCls.value = true

        }, 600)
        setTimeout(() => {
            inputTipCls.value = false
        }, 900)
    }
}
function replyClick(mail, master, parent) {
    replyInfo.mail = mail
    replyInfo.master = master
    replyInfo.parent = parent
    if (mail) {
        const titleEl = document.getElementById(commentInputId)
        scrollTo(titleEl.offsetTop)
    }
}
</script>

<style scoped lang="scss">
.Comment {
    border-top: 1px solid #eee;
    margin-top: 30px;
    padding-top: 30px;

    .input {
        .label {
            font-size: 18px;
            line-height: 30px;
            font-weight: 600;
            color: #252933;
            margin-bottom: 26px;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: #eee;
            margin-right: 16px;
            cursor: pointer;
            transition: transform .2s ease;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        .input_text {
            width: calc(100% - 56px);
            position: relative;
            padding: 8px 12px;
            color: #252933;
            outline: none;
            min-height: 64px;
            box-sizing: border-box;
            line-height: 22px;
            font-size: 14px;
            resize: both;

            border: 1px solid rgb(236, 236, 236);
            background: rgb(250, 250, 250);

            border-radius: 4px;
            transition: all .3s ease;

            &.focus,
            &:focus {
                background: #FFF;
                border: 1px solid #7952b3;
            }

            &.empty::before {
                content: attr(placeholder);
                pointer-events: none;
                user-select: none;
                color: #8a919f;
            }
        }

        .content {
            display: flex;

        }

        .enter_bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            padding-left: 56px;

            .emoji {
                position: relative;
                font-size: 14px;
                cursor: pointer;
                height: 100%;
                padding: 8px 10px;
                transition: all .2s ease;
                border-radius: 4px;
                color: #8a919f;

                &>span {
                    font-size: 16px;
                }

                &:hover {
                    background-color: rgb(250, 250, 250);

                    .emoji_list {
                        opacity: 1;
                        font-size: 20px;
                        border: 1px solid rgb(236, 236, 236);

                        &>div {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                .emoji_list {
                    position: absolute;
                    left: 0px;
                    top: 38px;
                    width: 300px;
                    background-color: rgb(250, 250, 250);
                    overflow-y: auto;
                    display: flex;
                    flex-wrap: wrap;
                    border: 0;
                    box-sizing: content-box;
                    font-size: 0px;
                    user-select: none;
                    border-radius: 4px;
                    transition: all .2s ease;
                    z-index: 99;
                    opacity: 0;

                    &>div {
                        width: 0px;
                        height: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;

                        &:hover {
                            background-color: #969696;
                        }
                    }
                }
            }

            .warn_info {
                color: #ca3e3e;
                font-size: 15px;
            }

            .reply_info {
                display: flex;
                color: #8a919f;
                font-size: 15px;

                .mail {
                    color: #7952b3;
                }

                .close {
                    width: 14px;
                    height: 14px;
                    position: relative;
                    cursor: pointer;

                    &::after,
                    &::before {
                        width: 100%;
                        height: 100%;
                        display: block;
                        content: "";
                        border-left: 1px solid #8a919f;
                        position: absolute;
                        top: 0;
                        left: 6px;
                    }

                    &::after {
                        transform: rotate(45deg);
                        top: 9px;
                    }

                    &::before {
                        transform: rotate(-45deg);
                        top: -1px;
                    }
                }
            }

            .post_btn {
                flex: 0 0 auto;
                width: 92px;
                text-align: center;
                font-size: 14px;
                line-height: 36px;
                background: #FFF;
                border-radius: 4px;
                color: #7952b3;
                padding: 0;
                cursor: pointer;
                border: 1px solid #7952b3;
                transition: all .3s ease;

                &:hover {
                    background: #7952b3;
                    color: #FFF;
                }
            }
        }
    }

    .comment_list {
        min-height: 200px;

        .comment_item {
            display: flex;
            padding: 16px 0;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: #eee;
            margin-right: 16px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .content {
            width: calc(100% - 56px);
        }

        .user_name {
            font-weight: 500;
            font-size: 15px;
            color: #252933;
            line-height: 26px;

            span {
                padding: 0 4px;
                font-size: 14px;
                line-height: 22px;
                color: #8a919f;
            }
        }

        .comment_text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #515767;
            text-align: justify;
            margin-top: 8px;
            word-break: break-all;
        }

        .info_bar {
            height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            color: #8a919f;
        }

        .parent_content {
            border: 1px solid #e4e6eb;
            border-radius: 4px;
            padding: 10px 12px;
            line-height: 20px;
            font-size: 14px;
            color: #8a919f;
            background-color: #f2f3f5;
            text-align: justify;
            margin-top: 8px;
        }

        .reply_count {
            cursor: pointer;
            fill: #8a919f;
            height: 22px;
            display: flex;
            align-items: center;

            &:hover {
                color: #7952b3;
                fill: #7952b3;
            }

            svg {
                width: 18px;
                height: 18px;
                transform: translateY(1px);
                margin-right: 5px;
            }
        }
    }

    .sub_comment {
        margin-top: 16px;
        padding: 16px;
        background: rgba(247, 248, 250, .7);
        border-radius: 4px;

        .sub_comment_item {
            display: flex;

            &:not(:first-child) {
                margin-top: 28px;
            }
        }

        .avatar {
            width: 24px;
            height: 24px;
            margin-right: 12px
        }
    }
}
</style>