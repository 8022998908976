<template>
    <div class='ArticleLoading'>
        <div class="c1" />
        <div class="c2" />
        <div class="c3" />
        <div class="c4" />
        <div class="c5" />
        <div class="c6" />
        <div class="c7" />
        <div class="c8" />
        <div class="c9" />
        <div class="c10" />
        <div class="c_line" v-for="(item, index) in textLine" :key="index"
            :style="'--w:' + item + '%' + ';--n:' + index + ';--o:' + item / 100" />
    </div>
</template>

<script setup>
const textLine = [36, 54, 73, 59, 41, 76, 58, 45, 84, 64, 51, 63, 85]
</script>

<style scoped lang="scss">
.ArticleLoading {
    position: absolute;
    top: 40px;
    width: calc(100% - 68px);
    background-color: rgb(255, 255, 255);
    height: 1000px;
    opacity: 1;

    div {
        position: absolute;
        background-color: #e2e6f0;
        border-radius: 4px;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            width: 100px;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-image: linear-gradient(to right, rgb(226, 230, 240), rgb(243, 243, 243), rgb(226, 230, 240));
            animation: theanima 2s ease infinite;
        }

        @keyframes theanima {
            0% {
                left: 0;
            }

            100% {
                left: 100%;
            }

        }
    }

    .c1 {
        width: 100%;
        height: 32px;
        top: 5px;
        opacity: .45;
    }

    .c2 {
        width: 46%;
        height: 32px;
        top: 47px;
        opacity: .45;
    }

    .c3 {
        width: 25%;
        height: 18px;
        top: 104px;
        opacity: .75;
    }

    .c4 {
        width: 15%;
        height: 18px;
        top: 104px;
        left: 26%;
        opacity: .95;
    }

    .c5 {
        width: 15%;
        height: 18px;
        top: 104px;
        right: 0;
        opacity: .55;
    }

    .c6 {
        width: 100%;
        height: 268px;
        top: 145px;
        opacity: .45;
    }

    .c_line {
        top: calc(423px + (var(--n) * 38px));
        height: 28px;
        width: var(--w);
        opacity: var(--o);
    }
}
</style>