import { reactive, ref } from 'vue';

const typeList = ref([]);
const typeMap = reactive({});

function setTypeList(value) {
    typeList.value = value;
    value.forEach((element) => {
        typeMap[element.id] = element;
    });
}

export { typeList, typeMap, setTypeList };
