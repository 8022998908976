<template>
    <div class='Banner' :style="{ backgroundImage: `url(${bg})` }" />
</template>

<script setup>
import { state } from "@/store/assets"
const bg = state['banner-bg']
</script>

<style scoped>
.Banner {
    width: 100%;
    height: 100%;
    background-size: cover;
}
</style>