<template>
  <Header />

  <Body />
  <router-view />
  <Footer />
</template>
<script setup>import Header from "@cp/Header/index.vue"
import Body from "@cp/Body"
import Footer from "@cp/Footer"
import { onMounted } from "vue";
onMounted(() => {
  window.shopInitLoading()
})
</script>
<style>
</style>
