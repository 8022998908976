export const mans = [
    '😀',
    '😁',
    '😂',
    '😃',
    '😄',
    '😅',
    '😆',
    '😉',
    '😊',
    '😋',
    '😎',
    '😍',
    '😘',
    '😗',
    '😙',
    '😚',
    '😇',
    '😐',
    '😑',
    '😶',
    '😏',
    '😣',
    '😥',
    '😮',
    '😯',
    '😪',
    '😫',
    '😴',
    '😌',
    '😛',
    '😜',
    '😝',
    '😒',
    '😓',
    '😔',
    '😕',
    '😲',
    '😷',
    '😖',
    '😞',
    '😟',
    '😤',
    '😢',
    '😭',
    '😦',
    '😧',
    '😨',
    '😬',
    '😰',
    '😱',
    '😳',
    '😵',
    '😡',
    '😠',
    '😈',
    '👿',
    '👹',
    '👺',
    '💀',
    '👻',
];

export function encode(str) {
    return str
    // for (let i in mans) {
    //     str = str.replace(new RegExp(mans[i], 'g'), encodeURI(mans[i]))
    // }
    // return str
}

export function decode(str) {
    return str
    // for (let i in mans) {
    //     // 检测不到直接返回
    //     if (str.indexOf("%") == -1) return str
    //     str = str.replace(new RegExp(encodeURI(mans[i]), 'g'), mans[i])
    // }
    // return str
}
