<template>
    <div class="Profile">
        <div class="image2" :style="{ backgroundImage: `url(${avatar2})` }"></div>
        <div class="image" :style="{ backgroundImage: `url(${avatar})` }"></div>
        <div class="name">CODEMEOW</div>
        <div class="hr"></div>
        <div class="text">不闻亦式</div>
        <div class="text sub">不谏亦入</div>
    </div>
</template>

<script setup>
import { state as aState } from "@/store/assets"

const avatar = aState["avatar"]
const avatar2 = aState["avatar-2"]
</script>

<style scoped lang="scss">
.Profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    transition: transform .2s ease;
    z-index: 10;

    &:hover {
        & .image2 {
            opacity: 1;
            width: 100%;
            height: 100%;
            transition-delay: .2s;
            border-radius: 4px;
        }

        & .text,
        & .name {
            color: #FFF;
            transition: color .2s .3s ease;
        }

        & .text {
            display: none;

            &.sub {
                display: block !important;
                animation: jellyShow .9s .7s ease forwards;
            }
        }

        & .hr {
            position: absolute;
            width: 100%;
            height: 100px;
            top: 214px;
            background-color: rgba(255, 192, 1, .4);
            transition: all .2s .6s ease;
        }

        transform: translateY(-4px) scale(1.07);
        box-shadow: -2px 4px 6px rgba(54, 54, 54, .1);
    }

    & .image2 {
        position: absolute;
        opacity: 0;
        transition: all .2s ease;
    }

    & .image {
        animation: right_to_left .9s ease;
    }

    & .image2,
    & .image {
        width: 188px;
        height: 188px;
        background-size: cover;
        border-radius: 100%;
        margin-bottom: 30px;
    }

    & .name {
        color: rgb(68, 68, 68);
        font-size: 24px;
        position: relative;
        z-index: 5;
        opacity: 0;
        animation: right_to_left .9s .3s ease forwards;
    }

    & .hr {
        width: 45px;
        height: 3px;
        background-color: rgba(255, 192, 1, 1);
        position: absolute;
        top: 265px;
        z-index: 4;
        opacity: 0;
        animation: right_to_left .9s .5s ease forwards;
    }

    & .text {
        color: rgb(119, 119, 119);
        white-space: nowrap;
        font-size: 14px;
        text-align: center;
        margin-bottom: 16px;
        margin-top: 32px;
        position: relative;
        z-index: 5;
        opacity: 0;
        animation: right_to_left .9s .7s ease forwards;

        &.sub {
            display: none;
        }
    }
}
</style>