<template>
    <div class='Prospects' @mousemove='move' @mouseleave='leave'>
        <img class="IS" ref='ISRef' :src='IS' alt="唯有时间对我们一视同仁" />
        <img class="HS" :src='HS' alt="codemeow" />
    </div>
</template>

<script setup>import { ref } from "vue";
import { state as aState } from "@/store/assets"

const ISRef = ref(null)
const IS = aState['individuality-signature']
const HS = aState['handwritten-signature']
function move(e) {
    ISRef.value.style.transition = "";
    const poor = e.pageX - window.innerWidth / 2;
    let res;
    if (poor > 0) {
        res = Math.sqrt(poor);
    } else {
        res = -Math.sqrt(-poor);
    }
    ISRef.value.style.transform = `translateX(${res}px)`;
}
function leave() {
    ISRef.value.style.transition = "transform 2s ease";
    ISRef.value.style.transform = "";
}
</script>

<style scoped lang="scss">
.Prospects {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-usertouch-callout: none;
        -moz-touch-callout: none;
        -ms-touch-callout: none;

        -webkit-user-drag: none;
        -moz-user-drag: none;
        -ms-user-drag: none;
    }

    .IS {
        height: 0px;
        animation: IS 1s .5s forwards;
    }

    .HS {
        height: 0px;
        position: absolute;
        right: 0px;
        bottom: 0px;
        animation: HS 1s forwards;
    }
}
</style>