import { reactive, computed } from 'vue';

const NAME_SPACE = 'SearchState';

let storageSearchState = sessionStorage.getItem(NAME_SPACE);
if (storageSearchState) {
    storageSearchState = JSON.parse(storageSearchState);
} else {
    storageSearchState = {
        types: [],
        tags: [],
        searchkey: '',
        pager: {
            totalPage: 0,
            currentPage: 1,
            pageSize: 5,
        },
    };
}
storageSearchState.articleList = [];
const searchState = reactive(storageSearchState);

const isEmptySelect = computed(() => {
    const { types, tags, pager, searchkey } = searchState;

    // 顺便存缓存
    sessionStorage.setItem(NAME_SPACE, JSON.stringify({ types, tags, pager, searchkey }));

    return types.length || tags.length;
});
const { types, tags, pager } = searchState;
async function postSearch(type, value, initPager = true) {
    switch (type) {
        case 'searchkey':
            searchState.searchkey = value;
            break;
        case 'currentPage':
            if (pager.currentPage == value) return;
            pager.currentPage = value;
            initPager = false;
            break;
    }
    if (initPager) {
        pager.totalPage = 0;
        pager.currentPage = 1;
    }
    requestSearch();
}
async function requestSearch() {
    const { success, data } = await $fetch.get('/publish/getArticleList', {
        curr: pager.currentPage,
        size: pager.pageSize,
        seaKey: searchState.searchkey,
        tags: tags.map((item) => item.id),
        types: types.map((item) => item.id),
    });
    if (success) {
        const { page, total } = data;
        searchState.articleList = page;
        pager.totalPage = Math.ceil(total / pager.pageSize);
        return Promise.resolve();
    }
}

function selectType(type) {
    const idx = types.findIndex((t) => t.id == type.id);
    if (idx !== -1) {
        types.splice(idx, 1);
    } else {
        types.push(type);
    }
    postSearch();
}

function selectTag(tag) {
    const idx = tags.findIndex((t) => t.id == tag.id);
    if (idx !== -1) {
        tags.splice(idx, 1);
    } else {
        tags.push(tag);
    }
    postSearch();
}
function removeSelectType() {
    while (types.length) {
        types.pop();
    }
    postSearch();
}
function removeSelectTag() {
    while (tags.length) {
        tags.pop();
    }
    postSearch();
}

export { searchState, isEmptySelect, selectType, types, selectTag, tags, pager, removeSelectType, removeSelectTag, postSearch, requestSearch };
