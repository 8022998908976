<template>
    <div/>
</template>

<script setup>
//  此路由用于旧博客重定向!!
import { useRoute, useRouter } from 'vue-router';
const route = useRoute()
const router = useRouter()
const aid = route.params.aid
if (aid) {
    router.replace({ name: 'home', query: { article: aid } })
}else{
    router.replace({ name: 'home', query: { article: -1 } })
}
</script>

<style scoped>
</style>