<template>
    <div class='Tags'>
        <div class="word_cloud" @mouseleave='mouseleave' ref='WordCloudCanvas' />
        <div @click='loadWordCloud' class="reload" :class="{ loading }" title="刷新">
            <Reload />
        </div>
    </div>
</template>

<script setup>
import { tagList } from "@/store/tags";
import wordcloud2 from '@/utils/wordcloud2.min.js'
import Reload from "@/svg/reload.svg"
import { selectTag } from "@/store/searchState";
import { onMounted, ref } from "vue";
const WordCloudCanvas = ref(null)
const loading = ref(false)
const options = {
    list: tagList.value.map((item) => [
        item.name,
        item.articles.length,
        item.color,
        item.id,
        item
    ]),
    gridSize: 8, // 密集程度 数字越小越密集
    weightFactor: 20, // 字体大小=原始大小*weightFactor
    minSize:5,
    color: (word, weight, fontSize, distance, theta, item) => item[0],
    classes: (word, weight, fontSize, item) => "word_item word_" + item[1],
    backgroundColor: "", // 背景颜色
    rotateRatio: 0.4, // 字体倾斜(旋转)概率，1代表总是倾斜(旋转)
    click(item) {
        selectTag(item[4])
    },
    hover(item) {
        if (item) {
            // 清除样式
            mouseleave();
            const className = "word_" + item[3];
            const elements = document.getElementsByClassName(className);
            if (elements.length) {
                const element = elements[0];
                const originTransform = element.style.transform.split(" ")[0];
                element.style.transform = originTransform + " scale(1.2)";
            }
        }
    },
};
onMounted(() => {
    loadWordCloud()
})
function loadWordCloud() {
    if (loading.value) return;
    loading.value = true
    setTimeout(() => {
        loading.value = false
    }, 1200);
    wordcloud2(WordCloudCanvas.value, options)
}

function mouseleave() {
    const wordItems = document.getElementsByClassName("word_item");
    for (let i = 0; i < wordItems.length; i++) {
        const wordItem = wordItems[i];
        const originTransform = wordItem.style.transform.split(" ")[0];
        wordItem.style.transform = originTransform;
    }
}
</script>

<style scoped lang="scss">
.Tags {
    margin-top: 20px;
    z-index: 5;
    position: sticky;
    top: 52px;

    :deep(.word_cloud) {
        width: 300px;
        height: 600px;
        user-select: none;
        cursor: pointer;
        position: relative;
        z-index: 1;
        background-color: #FFF;

        span {
            transition: transform .2s ease;
            transform-origin: transform text-shadow;

            &:hover {
                text-shadow: -1px 1px 3px rgba(0, 0, 0, .2);
            }
        }
    }

    .reload {
        position: absolute;
        right: 20px;
        top: 270px;
        width: 100px;
        height: 50px;
        padding: 20px;
        cursor: pointer;
        text-align: right;
        opacity: 0;
        transition: all .5s ease-in-out;
        box-sizing: content-box;

        svg {
            width: 30px;
            height: 30px;
            fill: #7952b3;
            transition: all .6s ease-in-out;
            padding: 10px;
            border-radius: 100%;
            box-sizing: content-box;
            background-color: rgba(255, 255, 255, .6);

            &:hover {
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 10px rgba(54, 54, 54, .18);
            }
        }
    }

    .loading {
        svg {
            transform: rotate(0deg) !important;
            transition-duration: 1s;
        }
    }

    &:hover {
        .reload {
            right: -100px;
            opacity: 1;

            svg {
                transform: rotate(-720deg);
            }
        }
    }
}
</style>