<template>
    <component style="transform: rotateY(180deg);" :is="getSvg()" />
</template>

<script setup>
import { svgMap } from "./svgMap"
const props = defineProps({
    name: String
})

function getSvg() {
    return svgMap[props.name]
}

</script>

<style scoped>
</style>