<template>
    <component :is="getTypesSvg(icon.name)" />
</template>

<script setup>
// type svg
import SvgBangbangtang from "@/svg/typeIcon/bangbangtang.svg"
import SvgBanji from "@/svg/typeIcon/banji.svg"
import SvgBaomihua from "@/svg/typeIcon/baomihua.svg"
import SvgBingkuai from "@/svg/typeIcon/bingkuai.svg"
import SvgBingsha from "@/svg/typeIcon/bingsha.svg"

const icon = defineProps({
    name: String
})

const typesIconMap = {
    bangbangtang: SvgBangbangtang,
    banji: SvgBanji,
    baomihua: SvgBaomihua,
    bingkuai: SvgBingkuai,
    bingsha: SvgBingsha,
}
function getTypesSvg(name) {
    return typesIconMap[name]
}
</script>

<style scoped>
</style>