<template>
    <div v-if="articleId" class='Directory' v-html='html' @click="handlerClick" />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { state } from "@/store/directory"
import { computed } from "vue";
import { scrollTo } from "@/utils/scroll"
const route = useRoute()

const articleId = computed(() => route.query && route.query.article)
const html = computed(() => {
    function recursive(list) {
        let str = ``
        for (let i = 0; i < list.length; i++) {
            str += `<div data-h="y" class="title_h ${list[i].tag}">${list[i].id}</div>
                    <div class="chils">${recursive(list[i].chils)}</div>`
        }
        return str
    }
    return recursive(state.directory)
})

async function handlerClick(e) {
    const { target } = e
    if (target.dataset.h == 'y') {
        const titleEl = document.getElementById(target.innerText)
        await scrollTo(titleEl.offsetTop)
        titleEl.style = 'background:#7952b3;color:#fff;transition:all .4s;'
        setTimeout(() => {
            titleEl.style = 'transition:all .4s;'
        }, 1000)
    }
}
</script>

<style scoped lang="scss">
.Directory ::v-deep {
    position: sticky;
    top: 70px;
    font-size: 14px;
    color: #333;

    .chils {
        padding-left: 2em;
        margin-top: 7px;
    }

    .title_h {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: #7952b3;
        }

        &.H1 {
            padding-left: 0em;
        }

        &.H2 {
            padding-left: 2em;
        }

        &.H3 {
            padding-left: 4em;
        }

        &.H4 {
            padding-left: 6em;
        }

        &.H5 {
            padding-left: 8em;
        }

        &.H6,
        &.H7,
        &.H8,
        &.H9 {
            padding-left: 10em;
        }

    }
}
</style>