export function scrollTop(time = 100) {
    return new Promise((reslove) => {
        const _d = document.scrollingElement;
        // 总高度
        const H = _d.scrollTop;
        // 滚动总时间
        const T = time;
        // 计算加速度
        const a = (-6 * H) / Math.pow(T, 3);
        // 当前时间
        let currTime = 0;
        let Interval = setInterval(() => {
            const v = a * currTime * (currTime - T);
            _d.scrollTop -= v;
            if (_d.scrollTop <= 0 || v < 0) {
                clearInterval(Interval);
                reslove();
            }
            currTime += 1;
        }, 1);
    });
}

export function scrollTo(pos, time = 100) {
    return new Promise((reslove) => {
        const _d = document.scrollingElement;
        // 总高度
        const H = _d.scrollTop - pos;
        // 滚动总时间
        const T = time;
        // 计算加速度
        const a = (-6 * H) / Math.pow(T, 3);
        // 当前时间
        let currTime = 0;
        let computeDown;
        if (_d.scrollTop >= pos) {
            computeDown = (v) => _d.scrollTop <= pos || v < 0;
        } else {
            computeDown = (v) => _d.scrollTop >= pos || v > 0;
        }

        let Interval = setInterval(() => {
            const v = a * currTime * (currTime - T);
            _d.scrollTop -= v;
            if (computeDown(v)) {
                clearInterval(Interval);
                reslove();
            }
            currTime += 1;
        }, 1);
    });
}
