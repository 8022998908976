<template>
    <div class='MusicPlayer'
        :class="{ playing: state.play, openPlay: state.openPlay, openPlayList: state.openPlayList }">
        <div class="play_list_bar">
            <div v-for="(mItem, index) in state.topList" :key="mItem.id" class="list_item"
                :class="{ currPlay: state.currPlayIndex == index }" @click="itemPlayClick(index)">
                <div class="number">{{ index + 1 }}</div>
                <div class="name" :title="mItem.name">
                    {{ mItem.name }}
                </div>
                <div class="duration">
                    {{ minuteTime(mItem.duration) }}
                </div>
                <div class="artists" :title="mItem.artists.map(item => item.name).join('/')">
                    {{ mItem.artists.map(item => item.name).join("/") }} </div>
            </div>
            <div class="tip">歌曲数据来源于网易云热榜<br>侵权请联系站长删除 ~</div>
        </div>
        <div class="play_bar">
            <div class="cover" :style="{ backgroundImage: `url(${toHttps(currPlayInfo.album.picUrl)})` }" />
            <div class="info_bar">
                <marquee scrollamount="4" onmouseover="this.stop()" onmouseout="this.start()" class="name">
                    {{ currPlayInfo.name }} - {{ currPlayInfo.artists.map(item => item.name).join("/") }}
                </marquee>
                <div class="lyrics">
                    <div class="lyrics_scroll" :style="{ transform: `translateY(-${currPlayLyricIndex * 20}px)` }">
                        <p v-for="item in state.currPlayLyricsArray" :key="item[1]">{{ item[0] }}</p>
                    </div>
                </div>
                <div class="process" @click="processClick">
                    <div class="thumb_bar" :style="{
                        width: (state.currPlayTime * 100000 / currPlayInfo.duration) + '%'
                    }">
                        <span class="thumb" @click.stop="_ => _" @mousedown="thumbDown" />
                    </div>
                </div>
                <div class="ctrl_bar">
                    <div class="countdown">{{ minuteTime(state.currPlayTime * 1000) }}</div>
                    <div class="btn_group">
                        <div class="sprite prev" @click="prevClick" />
                        <div class="sprite pause" @click="pauseClick" />
                        <div class="sprite play" @click="playClick" />
                        <div class="sprite next" @click="nextClick" />
                    </div>
                    <div class="list_down" @click="openPlayListBar" />
                </div>
            </div>
        </div>
        <div class="trigger" @click="openPlayBar">
            <img :src="logo" draggable="false">
        </div>
        <audio @ended="audioEnded" class="audio" @timeupdate="timeupdate" ref='audio' />
    </div>
</template>

<script setup>
import { computed, ref, reactive, watch } from "vue";
import { minuteTime, lyricsTime } from "@/utils/changeDateFormat";
import { getSimilarNumByArr } from "@/utils/math"
const logo = require("@assets/image/163music_lg.png")
const audio = ref(null)
const state = reactive({
    play: false,
    openPlay: true,
    openPlayList: true,
    topList: [], // 热榜
    currPlayIndex: -1,
    currPlaySong: { url: '' }, // 当前歌曲链接信息
    currPlayTime: 0,// 当前播放时间 
    currPlayLyricsArray: [], // 歌词结构
    lrcMSArr: [] // 歌词结构 - 毫秒
})
const MUSICPAUSE = "musicpause"

// 当前歌曲信息
const currPlayInfo = computed(() => {
    const r = state.topList[state.currPlayIndex] ||
    {
        album: { picUrl: require("@assets/image/muisic_empty.webp") },
        name: '- 未播放',
        artists: [],
        duration: 1,
    }
    return r
})

const currPlayLyricIndex = computed(() => {
    const ms = state.currPlayTime * 1000
    return getCurrPlayIndex(ms)
})

watch(() => state.play, (v) => {
    console.log(v);
    if (v) {
        localStorage.removeItem(MUSICPAUSE)
    } else {
        localStorage.setItem(MUSICPAUSE, '1')
    }
})
getTopList()
// 获取热榜
async function getTopList() {
    const { success, data } = await $fetch.get("/music/getToplist")
    if (success) {
        state.topList = data
        state.currPlayIndex = 0
        getSongPlayerUrl(!localStorage.getItem(MUSICPAUSE))
    }
}
// 获取播放信息链接
async function getSongPlayerUrl(fp = true) {
    const { success, data } = await $fetch.get("/music/getPlayerUrl", {
        id: currPlayInfo.value.id
    })
    if (success) {
        state.currPlaySong = data.data[0]
        audio.value.volume = 1
        if (state.currPlaySong.url) {
            audio.value.src = toHttps(state.currPlaySong.url)
            getSongLyrics()
            if (fp) {
                await forcePlay()
            }
        } else {
            // 付费歌曲
            nextClick()
        }
    }
}

// 获取歌词
async function getSongLyrics() {
    const { success, data } = await $fetch.get("/music/getLyric", {
        id: currPlayInfo.value.id
    })
    if (success) {
        const lyricStr = data.lrc.lyric
        const rawArr = lyricStr.split("\n")
        state.currPlayLyricsArray = []
        state.lrcMSArr = []
        //  ----------------
        rawArr.forEach((item, index) => {
            const matchRes = item.match(/\[(.*):(.*)\.(.*)\](.*)/)
            if (!matchRes) return
            const [_, s1, s2, s3, s4] = matchRes
            state.currPlayLyricsArray[index] = [s4, `${s1}.${s2}.${s3}`] //时间结构
            state.lrcMSArr[index] = (parseInt(s1) * 60 + parseInt(s2)) * 1000 + parseInt(s3)
        })
    }
}
// 递归 - 强制播放
async function forcePlay() {
    try {
        await audio.value.play()
        state.play = !audio.value.paused
    } catch (error) {
        console.log("-----fuck 不给播放");
        setTimeout(() => {
            forcePlay()
        }, 500)
    }
}

function itemPlayClick(index) {
    state.currPlayIndex = index
    getSongPlayerUrl()
}
function prevClick() {
    if (state.currPlayIndex == 0) {
        state.currPlayIndex = state.topList.length - 1
    } else {
        state.currPlayIndex--
    }
    getSongPlayerUrl()
}
function pauseClick() {
    audio.value.pause()
    state.play = !audio.value.paused
}
function playClick() {
    audio.value.play()
    state.play = !audio.value.paused
}

function nextClick() {
    if (state.currPlayIndex == state.topList.length - 1) {
        state.currPlayIndex = 0
    } else {
        state.currPlayIndex++
    }
    getSongPlayerUrl()
}
function audioEnded() {
    console.log('audioEnded');
    if (state.play) {
        nextClick()
    }
}
function openPlayBar() {
    if (state.openPlay) {
        if (state.openPlayList) {
            setTimeout(() => {
                state.openPlay = false
            }, 200)
            state.openPlayList = false
        } else {
            state.openPlay = false
        }
    } else {
        state.openPlay = true
        setTimeout(() => {
            state.openPlayList = true
        }, 200)
    }
}
function timeupdate() {
    state.currPlayTime = audio.value.currentTime
}
function openPlayListBar() {
    state.openPlayList = !state.openPlayList
}
// 从lrcMSArr计算播放的歌词index
function getCurrPlayIndex(ms) {
    // 提前处理边界
    const len = state.lrcMSArr.length
    if (ms <= state.lrcMSArr[0]) return 0
    if (ms >= state.lrcMSArr[len - 1]) return len - 1
    for (let i = 0; i < len; i++) {
        if (ms >= state.lrcMSArr[i] && ms < state.lrcMSArr[i + 1]) return i
    }
    return len - 1
}
// 进度拖动
function thumbDown(e) {
    // 记录当前状态
    const currPlayState = state.play
    audio.value.pause()
    state.play = false
    function mousemove(ee) {
        const dX = (ee.movementX / 200) * currPlayInfo.value.duration / 1000
        audio.value.currentTime += dX
    }
    function mouseup() {
        document.removeEventListener('mousemove', mousemove)
        document.removeEventListener('mouseup', mouseup)
        if (currPlayState) {
            audio.value.play()
            state.play = !audio.value.paused
        } else {
            state.play = false
        }
    }
    document.addEventListener('mousemove', mousemove)
    document.addEventListener("mouseup", mouseup)
}
// 进度点击
function processClick(e) {
    const { offsetX } = e
    const dX = (offsetX / 200) * currPlayInfo.value.duration / 1000
    audio.value.currentTime = dX
    state.currPlayTime = dX
}

// 转https
function toHttps(url) {
    return url.replace("http", "https")
}
</script>

<style scoped lang="scss">
.MusicPlayer {
    position: fixed;

    bottom: 160px;
    right: 40px;
    cursor: pointer;
    user-select: none;

    .play_bar {
        width: 0px;
        height: 90px;
        position: absolute;
        right: 40px;
        bottom: 0;
        box-shadow: 0px 4px 10px rgba(54, 54, 54, 0.18);
        background-color: #FFF;
        overflow: hidden;
        transition: width .2s ease;

        display: flex;
        cursor: default;

        .cover {
            width: 90px;
            height: 90px;
            background-size: cover;
        }

        .info_bar {
            width: 220px;
            height: 100%;

            .name {
                color: #333;
                font-size: 14px;
                margin-top: 6px;
            }

            .process {
                width: 200px;
                height: 2px;
                margin-left: 10px;
                background: #cdcdcd;
                cursor: pointer;

                .thumb_bar {
                    height: 100%;
                    background: #bb2128;
                    position: relative;
                }

                .thumb {
                    right: -4px;
                    top: -3px;
                    width: 8px;
                    height: 8px;
                    position: absolute;

                    background-color: #FFF;
                    border: 1px solid rgba(141, 77, 77, 0.2);
                    border-radius: 50%;
                }
            }

            .lyrics {
                margin-top: 2px;
                margin-bottom: 7px;
                height: 20px;
                width: 220px;
                font-size: 12px;
                color: #bb2128;
                overflow: hidden;

                .lyrics_scroll {
                    transition: transform .2s ease;
                }

                p {
                    text-align: center;
                    line-height: 20px;
                    height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .ctrl_bar {
                display: flex;
                margin-top: 6px;

                .countdown {
                    width: 60px;
                    font-size: 13px;
                    color: #333;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                }

                .list_down {
                    width: 60px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        content: "";
                        display: block;
                        width: 13px;
                        height: 10px;
                        cursor: pointer;
                        background: url(~@assets/image/music_sprite.png) no-repeat -58px -14px;
                    }
                }

                .btn_group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 20px;
                }


                .sprite {
                    background: url(~@assets/image/music_sprite.png) no-repeat 0 9999px;
                    margin: 0 10px;
                }

                .prev {
                    background-position: -33px 0;
                    width: 9px;
                    height: 10px;
                    cursor: pointer;
                }

                .pause {
                    background-position: -36px -63px;
                    width: 14px;
                    height: 18px;
                    cursor: pointer;
                    display: none;
                }

                .play {
                    background-position: 0 0;
                    width: 14px;
                    height: 18px;
                    cursor: pointer;
                    display: block;
                }

                .next {
                    background-position: -58px 0;
                    width: 9px;
                    height: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .play_list_bar {
        width: 310px;
        height: 0px;
        position: absolute;
        box-shadow: 0px 4px 10px rgba(54, 54, 54, 0.18);
        bottom: 90px;
        right: 40px;
        background-color: #FFF;
        overflow: hidden;
        overflow-y: auto;
        transition: height .2s ease;

        .tip {
            text-align: center;
            font-size: 13px;
            color: #333;
            padding: 20px 0;
            cursor: default;
            background-color: rgb(249, 249, 249);
        }

        .list_item {
            display: flex;
            height: 30px;
            line-height: 30px;

            .number {
                width: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                color: #999;

                padding-left: 5px;
            }

            .name {
                width: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-size: 12px;
                color: #333;
            }

            .duration {
                width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                color: #666;
            }

            .artists {
                width: 92px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-size: 12px;
                color: #333;
            }

            &:nth-child(2n-1) {
                background-color: #eee;
            }

            img {
                width: 20px;
                height: 20px;
            }

            &:hover {

                .number,
                .name,
                .duration,
                .artists {
                    color: #bb2128;
                }
            }

            &.currPlay {

                .number,
                .name,
                .duration,
                .artists {
                    color: #bb2128;
                }

                .number {
                    border-left: 3px solid #bb2128;
                    padding-left: 2px;
                }
            }
        }
    }

    .trigger {
        width: 33px;
        height: 33px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 3px;
        right: 4px;
        animation: jellyShow 0.5s ease forwards;
        transition: box-shadow .2s ease;
        border-radius: 50%;
        overflow: hidden;

        &:hover {
            box-shadow: 0px 4px 10px rgba(54, 54, 54, 0.38);
        }
    }

    .audio {
        display: none;
    }

    &.playing {
        img {
            animation: rotating 5s infinite;
        }

        .ctrl_bar {

            .btn_group {
                .play {
                    display: none;
                }

                .pause {
                    display: block;
                }
            }
        }
    }

    &.openPlay {
        .play_bar {
            width: 310px;
        }
    }

    &.openPlayList {
        .play_list_bar {
            height: 300px;
        }
    }
}
</style>