const defaultConfig = {
    baseUrl: '/api',
    timeout: 12000,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
};
const promiseWithOptions = function (options, interceptor) {
    let { headers, responseType, timeout, url, query, method, data, controller } = options;
    // 组装 query
    let queryString = '';
    if (query && typeof query === 'object') {
        const keys = Object.keys(query);
        if (keys.length) {
            queryString += '?';
            keys.forEach((key) => {
                queryString += `${key}=${query[key]}&`;
            });
            queryString = queryString.slice(0, queryString.length - 1);
        }
    }
    // 用于超时结束请求
    if (!controller) {
        controller = new AbortController();
    }
    const { signal } = controller;
    // 超时promise
    const timeoutPromise = () =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                controller.abort();
                reject(`请求超时,设置的时间:${timeout}`);
            }, timeout);
        });
    // 请求promise
    const requestPromise = () =>
        new Promise((resolve, reject) => {
            let body;
            if (data instanceof FormData) {
                // FormData 不序列化
                body = data;
                delete headers['Content-Type'];
            } else {
                body = JSON.stringify(data);
            }

            fetch(
                url + queryString,
                interceptor.request({
                    method,
                    body,
                    headers: new Headers(headers),
                    responseType,
                    signal,
                })
            ).then(
                (res) => {
                    if (responseType === 'json') {
                        res.json().then((data) => {
                            res.data = data;
                            resolve(interceptor.response(res));
                        });
                    } else {
                        resolve(res);
                    }
                },
                (err) => reject(err)
            );
        });
    // race
    return Promise.race([timeoutPromise(), requestPromise()]);
};
/**
 * @param {Object} options
 * @param {Object} config
 * 将options与config整合
 */
const optionsWithConfig = function (options, config) {
    options.headers = { ...config.headers, ...options.headers };
    options.timeout = options.timeout || config.timeout;
    options.responseType = options.responseType || config.responseType;
    return options;
};

const req = {
    config: defaultConfig,
    interceptor: {
        request: (config) => {
            return config;
        },
        response: (response) => {
            return response.data;
        },
    },
    request(options = {}) {
        options = optionsWithConfig(options, this.config);
        options.url = this.config.baseUrl + options.url;
        let { method } = options;
        if (!method) {
            method = 'GET';
        }
        options.method = method;
        return promiseWithOptions(options, this.interceptor);
    },
    get(url, p_query, options = {}) {
        options = optionsWithConfig(options, this.config);
        // get 请求的query 参数从形参p_query 和 options 中的query拿到
        let { query } = options;
        query = { ...p_query, ...query };
        options.url = this.config.baseUrl + url;
        options.query = query;
        options.method = 'GET';
        return promiseWithOptions(options, this.interceptor);
    },
    post(url, data, options = {}) {
        options = optionsWithConfig(options, this.config);
        options.url = this.config.baseUrl + url;
        options.data = data;
        options.method = 'POST';
        return promiseWithOptions(options, this.interceptor);
    },
};

export default req;
