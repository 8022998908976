import { reactive } from 'vue';


const state = reactive({
    avatar: 'man1',
    show: false
});

function setAvatar(value) {
    if (value) {
        state.avatar = value
    }
    close()
}
function open() {
    state.show = true
}
function close() {
    state.show = false
}

export {
    state, setAvatar, open,
    close
};
