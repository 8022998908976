import { computed, reactive } from 'vue';

const initImgStyle = {
    left: '50%',
    top: '50%',
    translate: '(-50%, -50%)',
    rotateX: 0,
    rotateY: 0,
    rotate: 0,
    scale: 1,
};
const state = reactive({
    srcList: [],
    styleRawList: [],
    curIndex: 0,
    show: false,
});

const swiperStyle = computed(() => {
    return {
        width: state.srcList.length * 100 + 'vw',
        transform: `translateX(-${state.curIndex * 100}vw)`,
    };
});

const imgStyleList = computed(() =>
    state.styleRawList.map((item) => ({
        left: item.left,
        top: item.top,
        transform: `translate${item.translate} rotate(${item.rotate}deg) rotateX(${item.rotateX}deg) rotateY(${item.rotateY}deg) scale(${item.scale})`,
    }))
);

function handlerMirrorX() {
    if (state.styleRawList[state.curIndex].rotateX) {
        state.styleRawList[state.curIndex].rotateX = 0;
    } else {
        state.styleRawList[state.curIndex].rotateX = 180;
    }
}
function handlerMirrorY() {
    if (state.styleRawList[state.curIndex].rotateY) {
        state.styleRawList[state.curIndex].rotateY = 0;
    } else {
        state.styleRawList[state.curIndex].rotateY = 180;
    }
}
function handlerRotate(v) {
    state.styleRawList[state.curIndex].rotate += v;
}
function handlerReset() {
    state.styleRawList[state.curIndex] = { ...initImgStyle };
}
function handlerSwiperMousedown(e) {
    if (e.target.tagName == 'IMG') {
        document.addEventListener('mousemove', imgMove);
    }
}
function handlerSwiperMouseup() {
    document.removeEventListener('mousemove', imgMove);
}

function imgMove(e) {
    const { clientX, clientY } = e;
    state.styleRawList[state.curIndex].left = clientX + 'px';
    state.styleRawList[state.curIndex].top = clientY + 'px';
}

function transCurr(index) {
    state.curIndex = index;
}
function close() {
    state.show = false;
    document.removeEventListener('keydown', escKeydown);
    document.removeEventListener('mousewheel', handerMousewheel, { passive: false });
}
function handerMousewheel(e) {
    e.preventDefault();
    if (e.deltaY < 0) {
        handlerBigger();
    } else {
        handlerSmaller();
    }
}
function handlerBigger() {
    state.styleRawList[state.curIndex].scale += 0.2;
}
function handlerSmaller() {
    if (state.styleRawList[state.curIndex].scale - 0.2 <= 0) return;
    state.styleRawList[state.curIndex].scale -= 0.2;
}
function open(list, index = 0) {
    document.addEventListener('keydown', escKeydown);
    document.addEventListener('mousewheel', handerMousewheel, { passive: false });
    state.srcList = list;
    state.styleRawList = state.srcList.map((_) => ({ ...initImgStyle }));
    state.curIndex = index;
    state.show = true;
}
function escKeydown(e) {
    if (e.key == 'Escape') {
        close();
    }
}

export {
    initImgStyle,
    state,
    swiperStyle,
    imgStyleList,
    handlerMirrorX,
    handlerMirrorY,
    handlerRotate,
    handlerReset,
    handlerSwiperMousedown,
    handlerSwiperMouseup,
    imgMove,
    transCurr,
    close,
    handerMousewheel,
    handlerBigger,
    handlerSmaller,
    open,
};
