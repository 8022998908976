<template>
    <nav class="Navigation" :class="{ fixed }" @mouseleave="rsetSliderStyle">
        <router-link @mouseenter="setSliderStyle" v-for="(navItem, index) in nav"
            :class="['item', JoiningNavClass(navItem.link)]" :key="index" :to="navItem.link">
            {{ navItem.name }}
        </router-link>
        <SearchInput @mouseenter="setSliderStyle" />
        <GithubStar @mouseenter="setSliderStyle" />
        <div class="slider" :style="sliderStyle" />
    </nav>
</template>

<script setup>import { watch, reactive, computed, onMounted, ref } from 'vue';
import GithubStar from "@cp/GithubStar"
import SearchInput from "@cp/SearchInput"
import { useRouter } from 'vue-router'
const router = useRouter()

const nav = reactive([
    { name: "首页", link: "/home" },
    // { name: "分类", link: "/type" },
    // { name: "标签", link: "/tag" },
    { name: "归档", link: "/archive" },
    { name: "关于小王", link: "/about" },
    { name: "流量统计", link: "/traffic" },
    { name: "友情链接", link: "/link" },
])

const sliderStyle = reactive({
    left: "0px",
    width: "0px"
})

const fixed = ref(false)

const pathClassName = computed(() => JoiningNavClass(router.currentRoute.value.path))

watch(pathClassName, rsetSliderStyle, {
    immediate: true
})

function rsetSliderStyle() {
    setSliderStyle({ target: document.getElementsByClassName(pathClassName.value)[0] })
}

function setSliderStyle(e) {
    if (!e.target) return
    const { offsetLeft, offsetWidth } = e.target;
    sliderStyle.left = offsetLeft + "px"
    sliderStyle.width = offsetWidth + "px"
}

function JoiningNavClass(className) {
    return `nav_${className}`
}
onMounted(() => {
    window.addEventListener("scroll", seFixed);
})
function seFixed() {
    fixed.value = window.scrollY > 180
}

</script>

<style scoped lang='scss'>
.Navigation {
    padding: 0 24px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 52px;
    display: flex;
    justify-items: center;
    position: relative;

    .item {
        color: #fff;
        font-size: 14px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        line-height: 52px;
        height: 52px;
        white-space: nowrap;
        margin-right: 12px;
        transition: all .2s ease;
        position: relative;
        z-index: 2;

        &:hover {
            transform: scale(1.3);
            font-weight: 700;
            line-height: 50px;
        }
    }

    .slider {
        height: 4px;
        position: absolute;
        border-radius: 2px;
        bottom: 0;
        background-color: #fff;
        z-index: 1;
        transition: all .5s ease-in-out;
    }

    &.fixed {
        position: fixed !important;
        top: 0;
        left: 0;
        animation: down .3s ease-in-out;
        background-color: #7952b3;
        box-shadow: 0 1.5px 5px rgba(0, 0, 0, 0.1);

        & .item {
            text-shadow: 0 1px 1px rgba(87, 142, 209, .3);
        }

        :deep {
            & .SearchInput {
                & .amplification {
                    div {
                        &::before {
                            border-color: #7952b3;
                        }

                        &::after {
                            background-color: #7952b3;
                        }
                    }

                    &.focus {
                        div {
                            &::after {
                                background-color: #7952b3;
                            }
                        }
                    }
                }
            }
        }



    }
}
</style>