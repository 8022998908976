import { reactive, ref } from 'vue';

const tagList = ref([]);

const tagMap = reactive({});

function setTagList(value) {
    tagList.value = value;
    value.forEach((element) => {
        tagMap[element.id] = element;
    });
}

export { tagList, tagMap, setTagList };
