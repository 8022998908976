import '@/style/init.scss';
import '@/style/keyframes.scss';

import { createApp } from 'vue';
import App from '@/App';
import router from '@/router';
import fetch from '@/utils/fetch';
import { initStore } from '@/store/init';
window.$fetch = fetch;

initStore().then((_) => {
    createApp(App).use(router).mount('#app');
});
