<template>
    <div ref="self" class='ArticleCover' :class="{
        reverse: isrReverse,
        passanm: pass
    }">

        <div class="content">
            <div class="title" :title="article.title">{{ article.title }}</div>
            <div class="description">{{ article.description }}</div>
            <div class="type">
                <TypeItem :name="article.icon" />
                <span class="typename">{{ article.type_name }}</span>
                <SvgFlag />
                <span class="flag">{{ article.flag }}</span>
            </div>
            <div class="information">
                <div>
                    <SvgDetails />
                    <span>{{ article.view_count }}</span>
                </div>
                <div>
                    <SvgComment />
                    <span>{{ article.comments }}</span>
                </div>
                <div>
                    <SvgDate />
                    <span>{{ formatDistance(changeDateFormat(article.update_time)) }}</span>
                </div>
            </div>
            <div class="tags">
                <TagItem v-for="id in article.tags.split(',')" :key="id" :name="tagMap[id].name"
                    :color="tagMap[id].color" />
            </div>
        </div>
        <div class="cover" :style="{ backgroundImage: `url(${article.first_picture})` }"></div>
    </div>
</template>

<script setup>import { onMounted, reactive, ref, watch } from 'vue';
import { changeDateFormat, formatDistance } from "@/utils/changeDateFormat";
import { useRoute } from 'vue-router';
import SvgDetails from "@/svg/details.svg"
import SvgFlag from "@/svg/flag.svg"
import SvgComment from "@/svg/comment.svg"
import SvgDate from "@/svg/date.svg"
import TypeItem from "@cp/TypeItem"
import TagItem from "@cp/TagItem"
import { tagMap } from "@/store/tags"
const route = useRoute()
watch(() => route.query && route.query.article, () => {
    pass.value = true
})

const OFFSET = 80;
const props = defineProps({
    data: Object
})
const article = reactive(props.data)

const isrReverse = article.title.length % 2 === 0
const pass = ref(false);
const self = ref(null);

onMounted(() => {
    document.addEventListener("scroll", adjust);
    adjust();
})

function adjust() {
    if (!self.value) return;
    const doc = document.documentElement;
    if (doc.scrollTop + doc.clientHeight >= self.value.offsetTop + OFFSET) {
        pass.value = true
        document.removeEventListener("scroll", adjust);
    }
}

</script>

<style scoped lang="scss">
.ArticleCover {
    position: relative;
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
    transition: transform .2s ease;
    background-color: #FFF;


    &:hover {
        transform: translateY(-4px) scale(1.07);
        box-shadow: 0px 4px 10px rgba(54, 54, 54, .18);

        .cover {
            transition-delay: .2s;
            width: 100%;
            left: 0px;
        }

        .content {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &.reverse {
            .content {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }

    .content {
        width: 470px;
        overflow: hidden;
        position: relative;
        z-index: 20;
        padding: 20px;
        background-color: rgba(255, 255, 255, .8);
        border-radius: 4px;

        &::before {
            content: '';
            display: block;
            width: 6px;
            height: 100%;
            position: absolute;
            left: 500px;
            opacity: 0;
            top: 0;
            background-color: #fff;
            transform: skew(-16deg);
            transition: all .5s ease;
        }

        & .type {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;

            .typename {
                color: #578ed1;
                margin-right: 10px;
            }

            .flag {
                color: rgb(165, 173, 246);
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }

            span {
                margin-right: 5px;
            }
        }

        & .title {
            font-weight: 700;
            font-size: 18px;
            color: #444;
        }

        & .description {
            color: #999;
            font-size: 14px;
            text-align: justify;
            margin-top: 20px;
        }

        & .tags {
            margin-top: 20px;
            display: flex;
        }

        & .information {
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            fill: #888888;
            color: #888888;

            &>div {
                display: flex;
                align-items: center;
                margin-right: 20px;

                &>svg {
                    width: 22px;
                    height: 22px;
                    margin-right: 4px;
                }

                & .svg-details {
                    width: 22px;
                    height: 22px;
                }

                & .svg-date {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .cover {
        position: absolute;
        height: 100%;
        width: 230px;
        left: 470px;
        z-index: 10;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        transition: all .3s ease-in;
    }


    &.reverse {
        justify-content: flex-end;

        .cover {
            left: 0px;
        }
    }

    & .cover,
    & .content {
        opacity: 0;
    }

    &.passanm {
        & .cover {
            animation: right_to_left .6s ease-in-out forwards;
        }

        & .content {
            animation: left_to_right .6s ease-in-out forwards;
        }

        &.reverse {
            & .cover {
                animation: left_to_right .6s ease-in-out forwards;
            }

            & .content {
                animation: right_to_left .6s ease-in-out forwards;
            }
        }

    }
}
</style>