<template>
    <div class='Pager'>
        <div class="page_last" @click="toLastPage">
            <SvgLeft />
            <SvgLeft />
        </div>
        <div class="page_curr" :class="animation">
            <div @click="toLastPage" class="last" v-show="pager.currentPage - 1 > 0">{{ pager.currentPage - 1 }}</div>
            <div class="cur">{{ pager.currentPage }}</div>
            <div @click="toNextPage" class="next" v-show="pager.currentPage + 1 <= pager.totalPage">{{ pager.currentPage
                    + 1
            }}
            </div>
        </div>
        <div class="page_next" @click="toNextPage">
            <SvgRight />
            <SvgRight />
        </div>
    </div>
</template>

<script setup>
import SvgRight from "@/svg/right.svg"
import SvgLeft from "@/svg/left.svg"
import { postSearch, pager } from "@/store/searchState"
import { ref } from "vue";
const animationClass = ['animation_left', 'animation_right']
const animation = ref("")
function toLastPage() {
    animation.value = animationClass[1]
    setTimeout(() => {
        animation.value = ""
        let page;
        if (pager.currentPage == 1) {
            page = pager.totalPage
        } else {
            page = pager.currentPage - 1
        }
        postSearch('currentPage', page)
    }, 200); // 等待动画,所以要等200毫秒
}
function toNextPage() {
    animation.value = animationClass[0]
    setTimeout(() => {
        animation.value = ""
        let page;
        if (pager.currentPage == pager.totalPage) {
            page = 1
        } else {
            page = pager.currentPage + 1
        }
        postSearch('currentPage', page)
    }, 200);
}
</script> 

<style scoped lang="scss">
.Pager {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-bottom: 20px;

    svg {
        width: 30px;
        height: 30px;
        fill: #7952b3;
        transition: all .2s ease;
        cursor: pointer;
    }

    .page_curr {
        display: flex;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        align-items: center;

        div {
            height: 100%;
            width: 60px;
            background-color: #7952b3;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            transform: skew(-16deg);
            transition: all .2s ease;
            border-radius: 6px;
        }

        .last {
            margin-right: 10px;
        }

        .next {
            margin-left: 10px;
        }

        .last,
        .next {
            height: 30px;
            cursor: pointer;
            opacity: .5;

            &:hover {
                height: 100%;
            }
        }

        &.animation_left {
            animation: snake_left .2s ease;
        }

        &.animation_right {
            animation: snake_right .2s ease;
        }

        @keyframes snake_left {
            0% {
                transform: translateX(0);
                opacity: 1;
            }

            100% {
                transform: translateX(-20px);
                opacity: 0;
            }
        }

        @keyframes snake_right {
            0% {
                transform: translateX(0);
                opacity: 1;
            }

            100% {
                transform: translateX(20px);
                opacity: 0;
            }
        }
    }

    .page_last {
        svg:nth-child(1) {
            opacity: 1;
        }

        svg:nth-child(2) {
            opacity: 0;
        }

        &:hover {
            svg:nth-child(2) {
                opacity: 1;
                transform: translateX(-30px) scale(1.1);
            }

            svg:nth-child(1) {
                opacity: 0;
                transform: translateX(-20px) scale(.3);
            }
        }
    }

    .page_next {
        svg:nth-child(1) {
            opacity: 0;
        }

        svg:nth-child(2) {
            opacity: 1;
        }

        &:hover {
            svg:nth-child(1) {
                opacity: 1;
                transform: translateX(30px) scale(1.1);
            }

            svg:nth-child(2) {
                opacity: 0;
                transform: translateX(20px) scale(.3);
            }
        }
    }
}
</style>