import { setTagList } from './tags';
import { setTypeList } from './types';
import { requestSearch } from './searchState';
import { setLocation } from './statistical';
import { initAssets } from './initAssets';
import { setAvatar } from "./visitorInfo"
// 在此加载首页的初始数据
async function initStore() {
    await initAssets();

    // 位置信息
    const { success: successTraffic, data: location } = await $fetch.get('/traffic/checkLocation');
    if (successTraffic) {
        setLocation(location);
    }

    // 设置游客信息,目前就只有头像
    const { success: successVisitor, data: visitorInfo } = await $fetch.get('/visitor/info');
    if (successVisitor) {
        setAvatar(visitorInfo && visitorInfo.avatar);
    }

    // 文章相关数据
    const { success: successByTypes, data: types } = await $fetch.get('/publish/getType');
    if (successByTypes) {
        setTypeList(types.map((item) => ({ ...item, articles: item.articles ? item.articles.split(',') : [] })));
    }
    const { success: successByTags, data: tags } = await $fetch.get('/publish/getTag');
    if (successByTags) {
        setTagList(tags.map((item) => ({ ...item, articles: item.articles ? item.articles.split(',') : [] })));
    }

    await requestSearch();
    return Promise.resolve();
}
export { initStore };
