<template>
    <div v-show="show" class='SearchInput' @click="inputFocus">
        <input ref="inputRef" @blur="inputBlur" @keyup.enter="search" type="text" :class="{ focus }">
        <div @click="search" class="amplification" :class="{ focus }">
            <div />
        </div>
    </div>
</template>

<script setup>import { computed, onMounted, ref } from 'vue';
import { searchState, postSearch } from "@/store/searchState"
import { useRoute } from 'vue-router';
const route = useRoute()
const focus = ref(false)
const inputRef = ref(null)

onMounted(() => {
    inputRef.value.value = searchState.searchkey
    if (inputRef.value.value) {
        setFocus(true);
    }
})

const show = computed(() => {
    return route.name==='home'
})

function inputFocus() {
    inputRef.value.focus();
    setFocus(true);
}


function inputBlur() {
    // 失去焦点 - 有值则不做操作 - 无值则清空搜索框并请求
    if (inputRef.value.value) return;
    else {
        inputRef.value.value = ""
        postSearch('searchkey', inputRef.value.value);
    }
    setFocus(false);
}

function search(e) {
    e.stopPropagation();
    if (inputRef.value.value) {
        postSearch('searchkey', inputRef.value.value);
    } else {
        inputFocus()
    }
}

function setFocus(value) {
    focus.value = value
}
</script>

<style scoped lang="scss">
.SearchInput {
    width: 400px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: 6px;
    cursor: text;
    border-radius: 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3) inset;
    position: absolute;
    left: calc(50% - 200px);

    input {
        border: none;
        height: 32px;
        width: 32px;
        margin-left: 4px;
        margin-top: 4px;
        padding-left: 10px;
        border-radius: 16px;
        position: absolute;
        right: 4px;
        transition: width .2s ease;

        &.focus {
            width: 392px;
        }
    }

    .amplification {
        width: 32px;
        height: 32px;
        background-color: #fff;
        position: absolute;
        right: 4px;
        top: 4px;
        border-radius: 16px;
        padding-top: 7px;
        padding-left: 7px;
        cursor: pointer;

        div {
            width: 16px;
            height: 16px;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                border: 2px solid #555;
                border-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                transition: all .2s ease;
            }

            &::after {
                content: "";
                display: block;
                width: 8px;
                height: 2px;
                background-color: #555;
                transform: rotate(45deg);
                position: absolute;
                left: 10px;
                top: 12px;
                transition: all .2s ease;
            }


        }

        &.focus div {
            &::before {
                width: 14px;
                height: 14px;
                border-radius: 14px;
            }

            &::after {
                width: 6px;
                height: 6px;
                background-color: #555;
                left: 6px;
                top: 6px;
                border-radius: 6px;
            }
        }
    }
}
</style>