<template>
    <div class="VIEW_Home" :class="{ show_article: showArticle }">
        <div class="left">
            <template v-if="searchState.articleList.length">
                <ArticleCover v-for="(article, index) in searchState.articleList"
                    :class="{ unfold: article.id == articleId }" :data="article" @click="coverClick(article, index)"
                    :key="article.id" />
                <Pager />
                <Directory />
            </template>
            <SearchEmpty v-else />
        </div>
        <Article :class="{ hidden: !showArticle }" />
        <div class="right">
            <Profile />
            <Types />
            <Tags />
        </div>
        <SelectBar :class="{ hidden: !isEmptySelect }" />
    </div>
</template>

<script setup>import { computed } from 'vue';
import { scrollTop } from "@/utils/scroll"
import Profile from "@cp/Profile"
import SearchEmpty from "@cp/SearchEmpty"
import ArticleCover from "@cp/ArticleCover"
import Article from "@cp/Article"
import Types from "@cp/Types"
import Tags from "@cp/Tags"
import Pager from "@cp/Pager"
import Directory from "@cp/Directory"
import SelectBar from "@cp/SelectBar"
import { useRoute, useRouter } from 'vue-router';
import { isEmptySelect, searchState } from "@/store/searchState"
const route = useRoute()
const router = useRouter()

const articleId = computed(() => route.query && route.query.article)
const showArticle = computed(() => !!articleId.value)

async function coverClick(article) {
    await scrollTop(200)
    router.push({ name: 'home', query: { article: article.id } })

}
</script>

<style scoped lang="scss">
.VIEW_Home {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    z-index: 9;

    :deep(.Article.hidden) {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    :deep(.SelectBar.hidden) {
        width: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
    }

    &>.left,
    &>.right {
        transition: transform .2s ease;
    }


    &>.left {
        width: 700px;
        margin-right: 20px;
        position: relative;
        z-index: 10;
    }

    &>.right {
        width: 300px;
        position: relative;
        z-index: 5;
    }



}

.show_article {
    :deep(.left) {
        width: 400px;
        // 文章状态下的封面
        @import "@cp/ArticleCover/showArticle.scss";
    }
}
</style>