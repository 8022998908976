
import Man1 from "@/svg/avatar/man1.svg"
import Man2 from "@/svg/avatar/man2.svg"
import Man3 from "@/svg/avatar/man3.svg"
import Man4 from "@/svg/avatar/man4.svg"
import Man5 from "@/svg/avatar/man5.svg"
import Man6 from "@/svg/avatar/man6.svg"
import Man7 from "@/svg/avatar/man7.svg"
import Man8 from "@/svg/avatar/man8.svg"
import Man9 from "@/svg/avatar/man9.svg"
import Man10 from "@/svg/avatar/man10.svg"
import Man11 from "@/svg/avatar/man11.svg"
import Man12 from "@/svg/avatar/man12.svg"
import Man13 from "@/svg/avatar/man13.svg"
import Man14 from "@/svg/avatar/man14.svg"
import Man15 from "@/svg/avatar/man15.svg"
import Man16 from "@/svg/avatar/man16.svg"
import Man17 from "@/svg/avatar/man17.svg"
import Man18 from "@/svg/avatar/man18.svg"
import Man19 from "@/svg/avatar/man19.svg"
import Man20 from "@/svg/avatar/man20.svg"

import Women1 from "@/svg/avatar/women1.svg"
import Women2 from "@/svg/avatar/women2.svg"
import Women3 from "@/svg/avatar/women3.svg"
import Women4 from "@/svg/avatar/women4.svg"
import Women5 from "@/svg/avatar/women5.svg"
import Women6 from "@/svg/avatar/women6.svg"
import Women7 from "@/svg/avatar/women7.svg"
import Women8 from "@/svg/avatar/women8.svg"
import Women9 from "@/svg/avatar/women9.svg"
import Women10 from "@/svg/avatar/women10.svg"
import Women11 from "@/svg/avatar/women11.svg"
import Women12 from "@/svg/avatar/women12.svg"
import Women13 from "@/svg/avatar/women13.svg"
import Women14 from "@/svg/avatar/women14.svg"
import Women15 from "@/svg/avatar/women15.svg"
import Women16 from "@/svg/avatar/women16.svg"
import Women17 from "@/svg/avatar/women17.svg"
import Women18 from "@/svg/avatar/women18.svg"
import Women19 from "@/svg/avatar/women19.svg"
import Women20 from "@/svg/avatar/women20.svg"

const manMap = {
    man1: Man1,
    man2: Man2,
    man3: Man3,
    man4: Man4,
    man5: Man5,
    man6: Man6,
    man7: Man7,
    man8: Man8,
    man9: Man9,
    man10: Man10,
    man11: Man11,
    man12: Man12,
    man13: Man13,
    man14: Man14,
    man15: Man15,
    man16: Man16,
    man17: Man17,
    man18: Man18,
    man19: Man19,
    man20: Man20,
}
const womenMap = {
    women1: Women1,
    women2: Women2,
    women3: Women3,
    women4: Women4,
    women5: Women5,
    women6: Women6,
    women7: Women7,
    women8: Women8,
    women9: Women9,
    women10: Women10,
    women11: Women11,
    women12: Women12,
    women13: Women13,
    women14: Women14,
    women15: Women15,
    women16: Women16,
    women17: Women17,
    women18: Women18,
    women19: Women19,
    women20: Women20,
}
const svgMap = {
    ...manMap,
    ...womenMap
}
export {
    manMap,
    womenMap,
    svgMap
}